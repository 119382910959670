<template>
   <div class="p-10">
      <div class="max-w-3xl px-5 mx-auto">
         <template v-if="role == ''">
            <div class="text-2xl font-medium mb-5 text-indigo-900">
               Start Transaction
            </div>
         </template>
         <template v-else>
            <template v-if="role != ''">
               <div class="flex font-semibold text-indigo-900 text-base py-5 hover:opacity-90 transition">
                  <span class="cursor-pointer justify-start flex" @click="cancelTransaction()">
                     <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                     </svg>
                     <span class="mx-3">Cancel Transaction</span>
                  </span>
               </div>
            </template>
         </template>
         <template v-if="role == ''">
            <div class="px-8 py-4 mx-auto bg-white rounded-lg shadow">
               <p class="text-center text-xl text-gray-600 font-medium pb-5 pt-5">Choose your role in this transaction</p>
               <div class="mt-2 grid grid-cols-2 gap-5 text-center mb-4">
                  <div class="border rounded-lg p-5 cursor-pointer hover:bg-gray-50 transition" :class="isSelected == 'buyer' ? 'bg-gray-100' : ''" @click="isSelected = 'buyer'">
                     <div class="font-medium text-indigo-900 text-xl my-5 flex justify-center space-x-2">
                        <div class="self-center">
                           <div class="h-4 w-4 rounded-full bg-white flex justify-center" :class="isSelected == 'buyer' ? 'border-2 border-indigo-900' : 'border border-gray-300'" >
                              <div class="bg-indigo-900 h-2 w-2 rounded-full self-center" v-if="isSelected == 'buyer'">
                                 
                              </div>
                           </div>
                        </div>  
                        <div>I'm a Buyer</div> 
                     </div>
                  </div>
                  <div class="border rounded-lg p-5 cursor-pointer hover:bg-gray-50 transition" :class="isSelected == 'seller' ? 'bg-gray-100' : ''" @click="isSelected = 'seller'">
                     <div class="font-medium text-indigo-900 text-xl my-5 flex justify-center space-x-2">
                        <div class="self-center">
                           <div class="h-4 w-4 rounded-full bg-white flex justify-center" :class="isSelected == 'seller' ? 'border-2 border-indigo-900' : 'border border-gray-300'" >
                              <div class="bg-indigo-900 h-2 w-2 rounded-full self-center" v-if="isSelected == 'seller'">
                                 
                              </div>
                           </div>
                        </div>  
                        <div>I'm the Seller</div> 
                     </div>               
                  </div>
               </div>
              <div class="mt-10 mb-6 text-center">
                 <div class="justify-end">
                    <button 
                     :class="isSelected == '' ? 'bg-indigo-300 hover:bg-indigo-400 cursor-not-allowed' : 'bg-indigo-900 hover:bg-indigo-900'" @click.prevent="isSelected != '' ? setTransactionRole(isSelected) : null" class="px-10 font-medium py-3 leading-5 text-white transition-colors duration-200 transform rounded-md focus:outline-none">Start transaction <span v-if="isSelected != ''">as</span> {{ isSelected }}</button>
                 </div>
               </div>
            </div>
         </template>
         <template v-else-if="role == 'buyer'">
            <buyer-transaction :role="role"></buyer-transaction>
         </template>
         <template v-else>
            <seller-transaction :role="role"></seller-transaction>
         </template>
      </div>
   </div>
</template>

<script>
import BuyerTransaction from './components/buyer-transaction.vue'
import SellerTransaction from './components/seller-transaction.vue'
import scrollToTop from '@/mixins/scrollToTop.js'

export default {
   name: "CreateTransaction",
   mixins: [scrollToTop],

   components: {
      BuyerTransaction,
      SellerTransaction
   },

   data() {
      return {
         role: "",
         isSelected: '',
      }
   },

   methods: {
      initRole() {
         // check if a role is active
         let getRole = window.localStorage.getItem('transaction-role');
         getRole = JSON.parse(getRole)

         if(getRole != null) {
            this.role = getRole.role
         } else {
            this.role = ''
         }
      },

      setTransactionRole(role) {
         let transactionRole = {}
         transactionRole.role = role
         transactionRole.step = 1
         this.role = role
         window.localStorage.setItem("transaction-role", JSON.stringify(transactionRole))
         this.scrollToTop()
      },
      
      cancelTransaction() {
         this.role = ''
         this.isSelected = ''
         window.localStorage.removeItem("transaction-role")
         this.scrollToTop()
      }
   },

   mounted() {
      this.initRole()
   }
   
}
</script>