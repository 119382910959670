<template>
  <div>
     <div class="relative mt-2" v-click-outside="closeDropdown">
         <span class="inline-block w-full rounded-md">
            <button type="button" @click="openDropdown" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" 
               class="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-3 text-left 
                  focus:outline-none focus:shadow-outline-blue focus:border-indigo-600 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
               <div class="flex items-center space-x-3">
                  <img :src="require(`@/assets/images/currency/${ value.logo }`)" alt="" class="flex-shrink-0 h-6 w-6 rounded-full" />
                  <span class="block truncate">
                     {{ value.name }}
                  </span>
               </div>
               <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                     <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
               </span>
            </button>
         </span>
      </div>
      <!-- Select popover, show/hide based on select state. -->
      <div v-show="isOptionOpen" class="absolute mt-0.5 z-20 w-full rounded-md bg-white shadow-lg">
         <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
            <li tabindex="0" @click="select(d)" id="listbox-item-0" role="option" v-for="d in data" :key="d.id" class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9  cursor-pointer hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600">
               <div class="flex items-center space-x-3">
                  <img :src="require(`@/assets/images/currency/${ d.logo }`)" alt="" class="flex-shrink-0 h-6 w-6 rounded-full" />
                     <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="block truncate" :class="{ 'font-normal' : !isOptionSelected(d) , 'font-semibold' : isOptionSelected(d)}">
                     {{ d.name }}
                  </span>
               </div>
               <!--
                  Checkmark, only display for selected option.
                  Highlighted: "text-white", Not Highlighted: "text-indigo-600"
                  -->
               <span v-show="isOptionSelected(d)" class="absolute inset-y-0 right-0 flex items-center pr-4">
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                     <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                  </svg>
               </span>
            </li>
         </ul>
      </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
   name: 'OgCustomSelectWithImage',

   props: {
      data: Array,
      value: Object
   },

   data() {
      return {
         isOptionOpen: false
      }
   },
   methods: {
      isOptionSelected(value) {
         return this.value === value;
      },
      closeDropdown() {
         this.isOptionOpen = false;
      },
      openDropdown() {
         this.isOptionOpen = true;
      },
      select(value) {
         this.isOptionOpen = false;
         this.$emit('valueSelect', value);
      }
   },
   directives: {
      ClickOutside
   }
}
</script>